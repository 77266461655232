import axios from 'axios';
import endpoints from '@/constants/endpoints';
import store from '@/store';

// Initialize a map to track active requests by endpoint
const cancelTokenSources = new Map();

const state = () => {
  return {
    router: null,
    endpoints,
    identification: null
  };
};

const getters = {
  router: state => state.router,
  getEndpoint: state => (endpoint, pathParams = {}) => {
    const get = (obj, keys) => {
      const [key, ...restKeys] = keys;

      if (!obj) throw new Error(`Endpoint ${endpoint} does not exist in endpoints.js`);

      return restKeys.length === 0 ? obj[key] : get(obj[key], restKeys);
    };

    let { url, method } = get(state.endpoints, endpoint.split('.')) ?? {};

    if (!url) {
      throw new Error(`Missing URL for endpoint ${endpoint}`);
    }

    if (!method) {
      throw new Error(`Missing method for endpoint ${endpoint}`);
    }

    url = url.replace(/(\{.*?\})/g, match => pathParams[match.replace(/[{}]/g, '')]);

    return {
      method,
      url
    };
  }
};

const actions = {
  async request (
    context,
    {
      endpoint,
      pathParams,
      responseType = 'json',
      queryParams = {},
      data,
      passHeaders = false,
      preventCancel = false
    }
  ) {
    let config = context.getters.getEndpoint(endpoint, pathParams);
    let query = queryParams;

    const excludeKeys = ['ro_f', 'ro_s', 'ro_p', 'page'];
    const queryWithoutExcludedKeys = Object.entries(queryParams)
      ?.reduce((acc, [key, value]) => {
        if (!excludeKeys.includes(key)) {
          acc.push(`${key}=${value}`);
        }
        return acc;
      }, [])
      ?.join('&');

    // Unique identifier based on URL only for GET requests
    const endpointKey = `${config.method}:${config.url}${queryWithoutExcludedKeys ? `?${queryWithoutExcludedKeys}` : ''}`;

    query = new URLSearchParams(query).toString();
    if (query) {
      config = {
        ...config,
        url: `${config.url}?${query}`
      };
    }

    // Cancel previous requests to the same endpoint (ignoring query parameters)
    if (!preventCancel && queryParams?.ro_f && cancelTokenSources.has(endpointKey)) {
      cancelTokenSources.get(endpointKey).forEach(cancelToken => cancelToken.cancel());
      cancelTokenSources.get(endpointKey).clear(); // Clear after canceling
    } else {
      cancelTokenSources.set(endpointKey, new Set());
    }

    const source = axios.CancelToken.source();
    cancelTokenSources.get(endpointKey).add(source);

    axios.interceptors.response.use(
      response => response,
      error => {
        const router = error?.config?.router;

        if (router) {
          if (error?.response?.data?.payload?.error === 'Unauthorized authenticated access.' && !localStorage.getItem('user')) {
            store.dispatch('user/clearUser');
            router.replace({ name: 'login' });
          } else {
            router.replace({ name: 'error' });
          }
        }

        return Promise.reject(error);
      }
    );

    try {
      const token = context.rootGetters['user/token'];
      const baseURL = context.rootGetters['client/api_url'];

      const response = await axios({
        ...config,
        data,
        baseURL,
        responseType,
        router: context.state.router,
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: source.token
      });

      return passHeaders ? response : response?.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return 'request-canceled'; // Return 'requestcancel' if the request was canceled
      }

      if (error) {
        // eslint-disable-next-line no-console
        console.error(error || error.message);
      }

      return error?.response?.data;
    } finally {
      // Remove the source from the set after the request completes or fails
      if (cancelTokenSources.get(endpointKey)?.delete(source)) {
        // If the source was successfully deleted and the set is empty, delete the key from the map
        if (!cancelTokenSources.get(endpointKey).size) {
          cancelTokenSources.delete(endpointKey);
        }
      }
    }
  },
  setRouter (context, value) {
    context.commit('SET_ROUTER', value);
  }
};

const mutations = {
  SET_ROUTER (state, value) {
    state.router = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
