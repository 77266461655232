<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    :enabled="task?.status !== 'Cancelled'"
    @click="onClickRedirect"
  >
    <template
      v-if="task?.job_status !== ALL_OPTIONS.PUBLISHED.value"
      #completed
    >
      <VDropdown
        :options="completedOptions"
        :label="$t('done')"
        icon-class="!ml-0"
        class="mr-0.5"
        button-class="text-grey-1 text-xs font-normal"
      />
    </template>

    <template #open>
      <VButton
        class="btn secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg round"
        :class="btnSize"
        @click="onClickRedirect"
      >
        {{ $t('view') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';
import VDropdown from '@/components/VDropdown';
// Constants
import ALL_OPTIONS from '@/constants/options';

export default {
  components: {
    VTask,
    VButton,
    VDropdown
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  emits: ['update'],
  setup (props, context) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const completedOptions = [
      {
        text: t('reopen'),
        value: 'reopen',
        click: () => {
          context.emit('update', {
            id: props.task?.id,
            data: {
              status: 'Open'
            }
          });
        }
      }
    ];

    // Methods
    const onClickRedirect = () => {
      let name = 'content-jobs-id-edit';
      if ([ALL_OPTIONS.PUBLISHED.value, ALL_OPTIONS.DEPRECATED.value].includes(props.task?.job_status) && props.task?.status === ALL_OPTIONS.COMPLETED.value) {
        name = 'content-jobs-id-description';
      }

      router.push({
        name,
        params: {
          id: props.task?.taskable_id
        }
      });
    };

    return {
      ALL_OPTIONS,
      completedOptions,
      onClickRedirect
    };
  }
};
</script>
