import ServiceUtil from '@/services/hrsg/ServiceUtil';
import useDownload from '@/composables/useDownload';
const { downloadFile } = useDownload();

class FilesService extends ServiceUtil {
  endpoint = 'platform.files';

  async show (id, type) {
    if (!id) {
      throw new Error('id parameter is required');
    }
    return await this.request({
      endpoint: `${this.endpoint}.show`,
      pathParams: { id },
      responseType: 'blob',
      type
    });
  }

  async delete (id) {
    if (!id) {
      throw new Error('id parameter is required');
    }
    return await this.request({
      endpoint: `${this.endpoint}.delete`,
      pathParams: { id }
    });
  }

  async downloadUuid (uuid) {
    if (!uuid) {
      throw new Error('id parameter is required');
    }
    const response = await this.request({
      endpoint: `${this.endpoint}.downloadUuid`,
      pathParams: { uuid }
    });

    if (response.message?.status === 'Failed') {
      return 404;
    }

    const downloadResponse = await this.request({
      endpoint: `${this.endpoint}.downloadUuid`,
      pathParams: { uuid },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(downloadResponse);
  }
}

export default new FilesService();
