<template>
  <div>
    <VNotification
      :item="notification"
      @toggle-read="$emit('toggle-read',notification)"
      @close="$emit('close')"
    >
      <template #header>
        <span>
          {{ $t('export_ready_to_download') }}
        </span>
      </template>
      <template #type>
        <div class="max-w-[75%]">
          <span class="cursor-default">
            {{ $t('download') }} :
          </span>
          <span @click="onClickDownload">
            {{ notification.data?.fileName }}
          </span>
        </div>
      </template>
    </VNotification>
  </div>
</template>

<script>
// Components
import VNotification from '@/components/templates/notifications/VNotification';
import FilesService from '@/services/hrsg/platform/FilesService';
// Services

export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props) {
    const onClickDownload = async () => {
      await FilesService.downloadUuid(props.notification.data?.fileId);
    };

    return {
      onClickDownload
    };
  }

};
</script>
